/**
 * Fonts
 */

@font-face {
  font-display: swap;
  font-family: "Cooper Hewitt";
  font-style: normal;
  font-weight: 700;
  src: url("cooperhewitt-heavyItalic.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: regular;
  font-weight: 400;
  src: url("montserrat-regular.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("montserrat-regular.woff2") format("woff2");
}

/**
 * Root
 */

:root {
  --blue-12: 213 55% 14%;
  --blue-11: 213 55% 20%;
  --blue-10: 213 54% 26%;
  --blue-7: 215 25% 49%;
  --blue-4: 215 25% 85%;
  --blue-2: 218 23% 91%;
  --blue-1: 218 24% 94%;
  --green-6: 169 55% 38%;
  --green-7: 169 53% 28%;
  --green-8: 169 55% 24%;
  --lime-5: 134 58% 72%;
  --lime-7: 134 58% 52%;
  --lime-8: 134 58% 42%;
  --pink-3: 327 100% 75%;
  --pink-5: 327 84% 60%;
  --pink-7: 327 58% 54%;
  --grey-12: 328 74% 0%;
  --grass-1-2: 144 21% 91%;
  --grass-2: 148 22% 68%;
  --grass-3: 148 22% 52%;
  --grass-4: 150 26% 43%;
  --grass-5: 149 25% 38%;
  --grass-8: 149 23% 28%;
  --grass-9: 148 23% 25%;
  --wheat-1: 60 50% 100%;
  --wheat-1-06: 45 8% 96%;
  --wheat-1-2: 48 10% 90%;
  --wheat-1-5: 51 8% 81%;
  --wheat-2: 45 8% 70%;
  --wheat-4: 45 3% 49%;
  --wheat-8: 48 3% 31%;
  --wheat-12: 60 3% 21%;
  --wheat-13: 60 2% 19%;
  --wheat-16: 60 3% 13%;
  --wheat-17: 60 2% 11%;
  --wheat-19: 60 3% 6%;
  --sky-2: 208 46% 72%;
  --sky-6: 204 50% 37%;
  --violet-2: 273 39% 77%;
  --violet-6: 270 32% 47%;
  --blood-2: 10 77% 76%;
  --blood-6: 2 73% 43%;
  --sun-1-1: 50 100% 87%;
  --sun-1-2: 50 100% 75%;
  --sun-1-5: 51 84% 57%;
  --sun-4: 50 73% 33%;
  --sun-7: 49 63% 25%;
  --sun-10: 49 57% 19%;
}

/**
 * Base
 */

html {
  background-color: hsl(var(--background, var(--wheat-50)));
  color: hsl(var(--text));
  color-scheme: light dark;
  font-family: Montserrat, sans-serif;
  font-size: 93.75%;

  @media (min-width: 40em) {
    font-size: 100%;
  }

  @media (min-width: 56em) {
    font-size: 106.25%;
  }

  @media (min-width: 72em) {
    font-size: 112.5%;
  }
}

body {
  background-color: hsl(var(--background));
  box-shadow: 0 8px 8px hsl(var(--drop));
  margin: 0 auto;
  max-inline-size: 90rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cooper Hewitt, sans-serif;
  text-transform: uppercase;
}

h1,
h2,
h3 {
  line-height: 1.2;
}

h1 {
  font-size: 3em;
  margin-block-end: 0.28em;
}

h2 {
  font-size: 2.5em;
  margin-block-end: 0.48em;
}

h3 {
  font-size: 2em;
  margin-block-end: 0.58em;
}

h4 {
  font-size: 1.5em;
  margin-block-end: 0.83em;
}

h5 {
  font-size: 1.17em;
  margin-block-end: 1em;
}

h6 {
  font-size: 1em;
  margin-block-end: 1.5em;
}

cite,
dd,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
li,
p {
  max-inline-size: 50ch;
}

address,
dd,
dt,
h4,
h5,
h6,
li,
p,
td,
th {
  line-height: 1.5;
}

blockquote,
figure {
  margin-inline: 2rem;
}

dd {
  margin-inline-start: 2rem;
}

ol,
ul {
  padding-inline-start: 2rem;
}

figcaption {
  margin-block: 0.48em;
}

sub,
sup {
  line-height: 1;
}

abbr {
  text-decoration: none;
}

hr {
  border: 1px solid;
  color: hsl(var(--separator));
  margin-inline: 0;
}

iframe,
img,
svg,
video {
  display: block;
  max-inline-size: 100%;
}

img {
  block-size: auto;
}

table {
  border-collapse: collapse;
  max-inline-size: 100%;
}

caption {
  text-align: start;
}

td,
th {
  border-block-end: 1px solid hsl(var(--separator));
  padding: 0.75rem 1.5rem 0.75rem 0;
  text-align: start;
}

form {
  margin-block: 1rem;
}

fieldset {
  border: none;
  max-inline-size: 100%;
  padding: 0;

  & > div {
    display: grid;
    grid-gap: 1.25rem;
    position: relative;

    & > div {
      position: relative;
    }
  }
}

legend {
  font-family: inherit;
  font-size: 1.5em;
  font-weight: 700;
  margin-block-end: 1rem;
}

label {
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  margin-block: 0.48em;

  & > input {
    margin-inline-end: 0.5rem;
  }
}

input,
select,
textarea {
  background-color: transparent;
  border: 1px solid hsl(var(--input));
  border-radius: 0.125rem;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  max-inline-size: 100%;
  padding: 0.5rem;

  &::placeholder {
    color: hsl(var(--text-secondary));
  }
}

audio,
input,
select,
textarea,
video {
  &:focus {
    box-shadow: 0 0 0 3px hsl(var(--focus));
    outline: 0;
  }
}

input[type="checkbox"],
input[type="radio"] {
  opacity: 0%;
  position: absolute;

  & + label {
    cursor: pointer;
    padding-inline-start: 2rem;
    position: relative;

    &::before {
      block-size: 1rem;
      border: 1px solid hsl(var(--input));
      border-radius: 0.125rem;
      content: "";
      inline-size: 1rem;
      inset-block-start: 0;
      inset-inline-start: 0;
      position: absolute;
      z-index: 1;
    }

    &::after {
      block-size: 0.25rem;
      border: 0 solid hsl(var(--input-icon));
      border-block-end-width: 0.25rem;
      border-block-start-color: transparent;
      border-inline-start-width: 0.25rem;
      content: "";
      inline-size: 0.75rem;
      inset-block-start: 0.25rem;
      inset-inline-start: 0;
      opacity: 0%;
      position: absolute;
      transform: rotate(-45deg) scale(0.75) translate(0.125rem);
    }
  }

  &:checked {
    & + label {
      &::after {
        opacity: 100%;
      }
    }
  }

  &:focus {
    & + label {
      &::before {
        box-shadow: 0 0 0 3px hsl(var(--focus));
      }
    }
  }
}

input[type="radio"] {
  & + label {
    &::before {
      border-radius: 50%;
    }

    &::after {
      background: hsl(var(--input-icon));
      block-size: 1rem;
      border: 1px solid hsl(var(--input-icon));
      border-radius: 50%;
      inline-size: 1rem;
      inset-block-start: 0;
      transform: scale(0.6);
    }
  }
}

input[type="range"] {
  appearance: none;
  border: none;
  box-shadow: none;
  padding: 0;

  &::-moz-range-track {
    background: hsl(var(--input));
    block-size: 0.125rem;
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    background: hsl(var(--input));
    block-size: 0.125rem;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background: hsl(var(--input-icon));
    block-size: 1rem;
    border: 1px solid hsl(var(--input-icon));
    border-radius: 50%;
    cursor: pointer;
    inline-size: 1rem;
    margin-block-start: -0.5rem;
    transform: translateY(1px);
  }

  &::-moz-range-thumb {
    appearance: none;
    background: hsl(var(--input-icon));
    block-size: 1rem;
    border: 1px solid hsl(var(--input-icon));
    border-radius: 50%;
    cursor: pointer;
    inline-size: 1rem;
    margin-block-start: -0.5rem;
    transform: translateY(1px);
  }

  &:focus {
    outline: none;

    &::-moz-range-thumb {
      box-shadow: 0 0 0 4px hsl(var(--focus));
    }

    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 4px hsl(var(--focus));
    }
  }
}

select {
  appearance: none;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      hsl(var(--input-icon)) 50%
    ),
    linear-gradient(135deg, hsl(var(--input-icon)) 50%, transparent 50%);
  background-position: calc(100% - 15px) 1rem, calc(100% - 10px) 1rem;
  background-repeat: no-repeat;
  background-size: 5px 5px, 5px 5px;
}

button {
  background-color: hsl(var(--accent));
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 0 0 hsl(var(--accent-drop));
  color: hsl(var(--on-accent));
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  max-inline-size: max-content;
  padding: 0.5rem 1.25rem;

  &:hover {
    background-color: hsl(var(--accent));
  }

  &:focus-visible {
    box-shadow: 0 2px 0 0 hsl(var(--focus-drop)),
      inset 0 1px 0 0 hsl(var(--focus-inset));
    color: hsl(var(--on-focus));
  }

  &:active {
    box-shadow: none;
    inset-block-start: 2px;
    position: relative;
  }
}

a,
button,
summary {
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    background-color: hsl(var(--focus));
    outline: none;
  }
}

a,
summary {
  &:focus-visible {
    box-shadow: 0px 0px 0px 4px hsl(var(--focus));
    color: hsl(var(--on-focus));
  }
}

a {
  color: hsl(var(--link));
  font-family: Cooper Hewitt, sans-serif;

  &:focus {
    color: hsl(var(--on-focus-link));
  }

  &:visited {
    color: hsl(var(--visited));

    &:focus-visible {
      color: hsl(var(--on-focus-visited));
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    color: hsl(var(--active));

    &:focus-visible {
      color: hsl(var(--on-focus-active));
    }
  }
}

::selection {
  background-color: hsl(var(--accent));
  color: hsl(var(--on-accent));
}

/**
 * Specifics
 */

p[is="lede"] {
  font-size: 120%;
}

/* Social */

section[is="social"] {
  background-image: linear-gradient(
    to bottom,
    hsl(var(--background)),
    hsl(var(--sunken)) 80%
  );
  border-bottom: 1px solid hsl(var(--separator));
  border-radius: 100% / 0 0 2rem 2rem;
  margin-block-start: -2rem;
  padding: 4rem 1rem 8rem;
  text-align: center;

  & > h2 {
    font-size: 2em;
    margin-block: 1em;
    margin-inline: auto;
  }

  & > p {
    margin-inline: auto;
    max-inline-size: 30rem;
  }

  & > ul {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 17rem));
    list-style: none;
    margin-block: 3rem 0 0 0;
    padding: 0;
    place-content: center;

    & > li {
      align-items: center;
      display: flex;
      max-inline-size: 30rem;
      text-align: center;

      & > a {
        align-items: center;
        background-color: hsl(var(--background));
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        padding: 1rem;
        inline-size: 100%;

        & > svg {
          display: inline-block;
          margin-block-start: -0.25rem;
          margin-inline-end: 0.25rem;
        }
      }
    }
  }
}

/* Audio guide */

section[is="audio-guide"] {
  padding: 4rem 2rem 0;
  display: grid;
  place-content: center;

  & > a {
    align-items: center;
    border-radius: 2rem;
    background-color: hsl(var(--sunken));
    border-radius: 0.5rem;
    display: flex;
    font-size: 120%;
    justify-content: center;
    padding: 2rem;

    & > svg {
      display: inline-block;
      margin-block-start: -0.25rem;
      margin-inline-end: 0.75rem;
    }
  }
}

/* Breadcrumbs */

ul[is="breadcrumbs"] {
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0;

  & > li {
    display: flex;
    list-style: none;

    &::before {
      background-color: hsl(var(--text) / 50%);
      block-size: 0.75rem;
      clip-path: polygon(
        0 3%,
        2% 0,
        22% 0,
        30% 2%,
        100% 48%,
        100% 52%,
        30% 98%,
        22% 100%,
        2% 100%,
        0 97%,
        70% 52%,
        70% 48%
      );
      content: "";
      inline-size: 0.5rem;
      inset-block-start: 0.5rem;
      margin-inline-end: 0.5rem;
      position: relative;
      transform: rotate(180deg);
    }

    &:not(:last-child) {
      display: none;
    }

    @media (min-width: 26rem) {
      &:first-child {
        &::before {
          display: none;
        }
      }

      &:not(:last-child) {
        display: flex;
      }

      &:not(:first-child) {
        &::before {
          margin-inline-start: 0.5rem;
          transform: rotate(0deg);
        }
      }
    }
  }
}

/* Call out */

article[is="call-out"] {
  background-color: hsl(var(--elevated));
  box-shadow: 8px 8px hsl(var(--drop));
  border-radius: 1rem;
  padding: 3rem 2rem;
  text-align: center;

  & > h2 {
    font-family: Cooper Hewitt, sans-serif;
    font-size: 1.5em;
    margin-block: 0;
  }
}

/* Dark mode toggle */

@keyframes --fade-out {
  0% {
    opacity: 0%;
  }

  60% {
    transform: translateY(-0.25rem);
  }

  100% {
    opacity: 0%;
    transform: translateY(0);
  }
}

dark-mode-toggle {
  --dark-mode-toggle-label: hsl(var(--toggle-label));
  --dark-mode-toggle-track: hsl(var(--toggle-track));
  --dark-mode-toggle-thumb: hsl(var(--toggle-thumb));
  --dark-mode-toggle-focus: hsl(var(--focus));

  font-size: 90%;
  grid-column: 3;
  grid-row: 1;
}

p[is="dark-mode-info"] {
  animation: 0.3s --fade-out 5s ease-in forwards;
  background-color: hsl(var(--top));
  inset-block-start: 4rem;
  inset-inline-end: 1rem;
  max-inline-size: 10rem;
  opacity: 0%;
  padding: 1rem 1.5rem;
  position: absolute;
  transform: translateY(-0.25rem);
  z-index: 2;

  &::before {
    background-color: hsl(var(--top));
    block-size: 1.5rem;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    content: "";
    inline-size: 1.5rem;
    inset-block-start: calc((1.5rem - 1px) * -1);
    inset-inline-end: 1.5rem;
    position: absolute;
  }

  @media (prefers-reduced-motion) {
    animation-duration: 0s;
  }

  @media (min-width: 30em) {
    inset-block-start: 5rem;
  }
}

/* Features */

section[is="features"] {
  border-block-end: 1px solid hsl(var(--separator));
  border-radius: 100% / 0 0 5rem 5rem;
  margin-inline: auto;
  max-inline-size: 50rem;
  padding: 3rem 2rem 4rem;

  & > h2 {
    font-size: 1.5em;
    margin-inline: auto;
    text-align: center;
  }

  & > ul {
    align-items: flex-end;
    display: grid;
    grid-gap: 3rem;
    justify-content: center;
    margin-block-start: 3rem;
    padding-inline-start: 0;

    & > li {
      display: grid;
      font-size: 1.17em;
      grid-gap: 1rem;
      justify-content: center;
      list-style: none;
      padding: 0;

      & > svg {
        justify-self: center;
      }
    }

    @media (min-width: 24em) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 56rem) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

/* Footer */

footer[role="contentinfo"] {
  padding: 1rem 1rem 2rem;
  text-align: center;

  & > svg {
    margin-inline: auto;
    opacity: 0%;
  }

  & > small {
    display: block;
    margin-block-start: 1rem;
  }
}

/* Header */

header[role="banner"] {
  align-items: center;
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-template-rows: repeat(1, 1fr);
  justify-content: space-between;
  margin-inline: auto;
  max-inline-size: 50rem;
  padding: 1rem;
  position: relative;
  z-index: 3;

  & > a {
    display: block;

    & > svg {
      block-size: auto;
      inline-size: 6rem;
    }
  }

  & > nav {
    grid-column: 1 / span 3;
    grid-row: 2;

    & > ul {
      display: flex;
      justify-content: center;
      list-style: none;
      margin-block: 2.25rem 0 0 0;
      padding-inline-start: 0;

      & > li {
        align-items: center;
        display: flex;
        margin-block-start: 0.5rem;

        & > a.current {
          box-shadow: inset 0 -3px hsl(var(--link));
          border-radius: 0;
          padding: 1rem 0 0.7rem;
          margin-inline: 1.25rem;

          &:hover {
            background-color: hsl(var(--background));
          }
        }

        & > a {
          border-radius: 0.5rem;
          display: block;
          font-family: Cooper Hewitt, sans-serif;
          font-size: 110%;
          font-weight: 700;
          padding: 1rem 1.25rem 0.7rem;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            background-color: hsl(var(--sunken));
          }
        }
      }
    }

    @media (min-width: 40em) {
      grid-column: 2 / -1;
      grid-row: 1;

      & > ul {
        margin-block: 0;
      }
    }
  }

  @media (min-width: 30em) {
    padding-block-start: 2rem;

    & > a {
      & > svg {
        inline-size: 10rem;
      }
    }
  }
}

/* Hero */

section[is="hero"] {
  border-radius: 100% / 0 0 2rem 2rem;
  margin-block-end: -1rem;
  padding: 2rem 4rem 5rem;
  position: relative;
  text-align: center;
  z-index: 0;

  &::before {
    background: url("assets/images/wave.svg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 130%;
    content: "";
    display: block;
    inset-block-end: 0;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    position: absolute;
    opacity: 1;
    z-index: -1;

    @media (min-width: 50rem) {
      background-position: bottom center;
      background-size: 140%;
    }

    @media (min-width: 80rem) {
      background-position: bottom center;
      background-size: cover;
    }
  }

  & > h1,
  & > h2 {
    font-size: 1.55em;
    margin-block: 0 0 0.38em 0;
    margin-inline: auto;
    z-index: 2;

    @media (min-width: 24em) {
      font-size: 1.73em;
    }

    @media (min-width: 28em) {
      font-size: 2em;
      margin-block: 1em 0 0.38em 0;
    }
  }

  & > p {
    font-family: Montserrat, sans-serif;
    font-size: 1.3em;
    font-style: italic;
    font-weight: 400;
    margin-inline: auto;
    max-inline-size: 40ch;
    z-index: 2;
  }

  @media (min-width: 30rem) {
    padding: 1rem 8rem 6rem;
  }

  @media (min-width: 60rem) {
    padding: 4rem 3rem 7rem;
    margin-block-end: -3rem;
  }
}

/* Image */

img[is="in-action"] {
  border-radius: 0.5rem;
}

/* Rich content */

section[is="rich-content"] {
  padding: 3rem 2rem 0;

  & > h2,
  & > h3 {
    font-size: 1.7em;
    margin-block-start: 3.2rem;
    margin-block-end: 1.73rem;
  }

  & > h2,
  & > h3,
  & > h4,
  & > p,
  & > dl {
    margin-inline: auto;
    text-align: center;
    max-inline-size: 38em;

    & > dt {
      font-family: Cooper Hewitt, sans-serif;
      padding-block-start: 1rem;
      font-size: 120%;
    }

    & > dt,
    & > dd {
      text-align: center;
      margin-inline: auto;
    }
  }
}

/* Brand */

section[is="brand-content"] {
  padding: 2rem 2rem;

  & > article:nth-child(even) {
    @media (min-width: 50em) {
      & > img {
        grid-column: 2;
      }
      & > div {
        grid-column: 1;
        padding-inline-start: 0;
        padding-inline-end: 2rem;
      }
    }
  }

  & > h3 {
    font-size: 1.7em;
    margin-block-start: 3.2rem;
    margin-block-end: 1.73rem;
  }

  & > h3,
  & > h4,
  & > p {
    margin-inline: auto;
    text-align: center;
    max-inline-size: 38em;
  }
}

article[is="brand"] {
  display: grid;
  margin-block-end: 2rem;
  margin-block-start: 4rem;

  & > h3 {
    font-size: 1.7em;
    margin-block-start: 2.2rem;
    margin-block-end: 1.53rem;
  }

  & > div {
    justify-self: start;
    grid-row: 2;

    @media (min-width: 50em) {
      align-self: center;
      grid-column: 2;
      grid-row: span 2;
      justify-self: center;
      padding-inline-start: 2rem;
    }
  }

  & > img {
    align-self: center;
    grid-column: 1;
    grid-row: 1;
    justify-self: center;

    @media (min-width: 50em) {
      justify-self: end;
    }
  }

  @media (min-width: 50em) {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-inline: auto;
    max-inline-size: 52em;
  }
}

/* Zine */

aside[is="zine-content"] {
  padding: 3rem 2rem;

  & > h3 {
    font-size: 1.7em;
    margin-block-start: 2.2rem;
  }

  & > h3,
  & > p {
    margin-inline: auto;
    text-align: center;
    max-inline-size: 38em;
  }
}

article[is="zine"] {
  display: grid;
  background-color: hsl(var(--elevated));
  border-radius: 1rem;
  box-shadow: 0 4px 0 0 hsl(var(--drop)), inset 0 1px 0 0 hsl(var(--inset));
  overflow: hidden;

  & > div {
    padding: 2rem;
  }

  @media (min-width: 50em) {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-inline: auto;
    max-inline-size: 55em;

    & > div {
      align-self: center;
      grid-column: 2;
      grid-row: span 2;
    }

    & > img {
      align-self: center;
      grid-column: 1;
      grid-row: span 2 / -1;
      justify-self: start;
    }
  }
}

/* Intro */

section[is="intro"] {
  border-radius: 100% / 0 0 2rem 2rem;
  margin-block-start: 1rem;
  margin-block-end: -1rem;
  overflow: hidden;
  padding: 3rem 1.5rem 3rem;
  position: relative;
  text-align: center;
  z-index: 0;

  &::after {
    background: url("assets/images/banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 300%;
    content: "";
    display: block;
    inset-block-end: 0;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-inline-start: 0;
    opacity: 0.1;
    position: absolute;
    z-index: 1;

    @media (min-width: 28em) {
      background-size: cover;
    }
  }

  & > h1,
  & > h2 {
    font-size: 1.73em;
    margin-block: 0 0 0.48em 0;
    margin-inline: auto;
    z-index: 2;

    @media (min-width: 24em) {
      font-size: 2em;
    }

    @media (min-width: 28em) {
      font-size: 2.5em;
      margin-block: 1em 0 0.48em 0;
    }
  }

  & > p {
    font-family: Montserrat, sans-serif;
    font-size: 1.5em;
    font-style: italic;
    font-weight: 400;
    margin-inline: auto;
    z-index: 2;
  }

  @media (min-width: 30em) {
    padding: 1rem 1.5rem 3rem;
  }
}

/* Hint */

p[is="hint"] {
  color: hsl(var(--text-secondary));
}

/* Pagination */

ul[is="pagination"] {
  display: grid;
  grid-gap: 4rem;
  margin-block: 4rem 2rem 2rem;
  margin-inline: auto;
  max-inline-size: 50rem;
  padding-inline: 2rem;

  & > li {
    list-style: none;

    & > a {
      display: inline-block;
      padding-inline-start: 1rem;
      position: relative;

      &::before {
        background-color: currentColor;
        block-size: 0.75rem;
        clip-path: polygon(
          0 3%,
          2% 0,
          22% 0,
          30% 2%,
          100% 48%,
          100% 52%,
          30% 98%,
          22% 100%,
          2% 100%,
          0 97%,
          70% 52%,
          70% 48%
        );
        content: "";
        display: block;
        inline-size: 0.5rem;
        inset-inline-start: 0;
        margin-block-start: 0.5rem;
        margin-inline-end: 0.5rem;
        position: absolute;
        text-decoration: none;
        transform: rotate(180deg);
      }

      & > span {
        display: block;

        &:first-child {
          font-size: 1.17em;
          font-weight: 700;
        }
      }
    }

    &:first-child {
      justify-self: end;
    }

    &:last-child {
      grid-column: 2;
      margin-block-start: 0;

      & > a {
        &::before {
          transform: rotate(0deg);
        }
      }
    }
  }
}

/* Promos */

section[is="promos"] {
  background-image: linear-gradient(
    to bottom,
    hsl(var(--sunken)),
    hsl(var(--background)) 70%
  );
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 24rem));
  padding: 4rem 1.5rem 1rem;
  place-content: center;

  @media (min-width: 60em) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 17rem));
    padding: 7rem 1.5rem 1rem;
  }
}

a[is="promo"] {
  background-color: hsl(var(--elevated));
  border: 1px solid hsl(var(--separator));
  border-radius: 0.5rem;
  box-shadow: 0 4px 0 0 hsl(var(--drop)), inset 0 1px 0 0 hsl(var(--inset));
  color: hsl(var(--link));
  display: block;
  padding: 2rem;
  text-decoration: none;

  &:active {
    box-shadow: none;
    inset-block-start: 2px;
    position: relative;
  }

  & > h2,
  & > h3,
  & > h4 {
    text-decoration: underline;
    font-size: 1.5em;
    margin-block: 0;

    &:hover {
      text-decoration: none;
    }
  }

  & > p {
    color: hsl(var(--text));
    font-family: "Montserrat", sans-serif;
  }
}

/* Signpost */

a[is="signpost"] {
  font-size: 1.17em;
  font-weight: 700;

  &::after {
    background-color: currentColor;
    block-size: 0.75rem;
    clip-path: polygon(
      0 3%,
      2% 0,
      22% 0,
      30% 2%,
      100% 48%,
      100% 52%,
      30% 98%,
      22% 100%,
      2% 100%,
      0 97%,
      70% 52%,
      70% 48%
    );
    content: "";
    display: inline-block;
    inline-size: 0.5rem;
    line-height: 1;
    margin-inline-start: 0.5rem;
    text-decoration: none;
  }
}

/* Skip */

a[is="skip"] {
  color: hsl(var(--on-focus));
  display: block;
  inset-inline-start: -100vw;
  margin-block-end: 0.75rem;
  padding: 0.5rem 2rem;
  position: absolute;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    background-color: hsl(var(--focus));
    position: static;
  }
}

/* Statistics */

aside[is="statistics"] {
  padding: 3rem 1.5rem 0;
  text-align: center;

  & > p {
    background-color: hsl(var(--accent));
    border-radius: 67% 33% 65% 35% / 26% 51% 49% 74%;
    color: hsl(var(--on-accent));
    margin: -5rem auto 3rem;
    max-inline-size: 10rem;
    padding: 2rem 3rem;
  }
}

/* Swatches */

ul[is="swatches"] {
  margin-block-end: 1.5em;
  padding-inline-start: 0;

  & > li {
    align-items: center;
    block-size: 3rem;
    border: 1px solid hsl(var(--separator));
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    inline-size: 3rem;
    list-style: none;
    margin-block-end: 1rem;
    position: relative;
    text-align: center;
    text-indent: 4rem;
    white-space: nowrap;
  }
}

/* Swatches scale */

ul[is="swatches-scale"] {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-inline-size: 40rem;
  padding-inline-start: 0;

  & > li {
    block-size: 3rem;
    box-sizing: border-box;
    inline-size: 3rem;
    list-style: none;
    margin-block-end: 2rem;
    padding-block-start: 3rem;
    text-align: center;
  }
}

/* Table of contents */

div[is="table-of-contents"] {
  margin-block-start: 2rem;
  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & ol {
    counter-reset: table-of-contents;
    list-style: none;
    padding: 0;

    & > li {
      min-inline-size: 10rem;
      padding: 0.23em 0;

      &::before {
        content: counters(table-of-contents, ".") " ";
        counter-increment: table-of-contents;
      }

      & > ol {
        margin: 0.5rem 0 0 1rem;
      }
    }
  }
}
